import ImageStudenPatent from '@app/asset/image/student-patent.png'
import { HorizontalSpacer } from '@app/ui/layout/HorizontalSpacer';
import { FeatureSection } from '@app/ui/marketing/FeatureSection';
import { Text, Container, Title, Box } from '@mantine/core';
import ImageAiRobot from '@app/asset/image/ai-robot.png';
import ImageLegal from '@app/asset/image/legal.png';
import classes from './style.module.css';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import Image from 'next/image';

/* eslint-disable max-len */


export const FeatureBody : RFC = () =>  {

    const { isMobile, isTablet } = useContext(App);

    return (
        <Box>
            <FeatureSection
                side='left'
                mediaComponent={
                    <Image
                        hidden={isMobile}
                        alt='AI Assistance for Patent Creation'
                        width={640}
                        height={640}
                        src={ImageAiRobot.src} />
                }
                subtitle='The AI Assisted Patent Partner simplifies patent creation for all, making it accessible to everyone regardless of skill level. By streamlining the process and overcoming skill barriers, it empowers inventors and legal professionals alike to navigate the patent creation journey with ease.'
                footer='The AI Assisted Patent Partner simplifies patent creation, making it accessible to all skill levels. By streamlining the process and overcoming barriers, it empowers both inventors and legal professionals to navigate the journey effortlessly.'
                title='AI ASSISTED PATENT PARTNER'
                itemList={[{
                    title    : 'Accessible Patent Assistance',
                    subtitle : 'Makes patent completion accessible to everyone',
                }, {
                    title    : 'Streamlined Process for All',
                    subtitle : 'Simplifies patent creation for inventors and legal assistance',
                }, {
                    title    : 'Overcoming Skill Barriers',
                    subtitle : 'No expertise required to begin the process',
                }]} />
            <br />
            <HorizontalSpacer size='sm' />
            <br />

            <FeatureSection
                side='right'
                mediaComponent={
                    <Image
                        hidden={isTablet}
                        alt='Legal Image'
                        width={400}
                        height={400}
                        src={ImageLegal.src} />
                }
                title='BENEFITS TO LEGAL PROFESSIONALS'
                subtitle='AttorneyAI streamlines patent processing, reducing time and effort for legal professionals. It enhances client acquisition by providing easier access to potential clients and cases through curated patent databases. Additionally, it helps mitigate the risk of errors and rejections in patent applications, ensuring smoother processes.'
                footer='AttorneyAI streamlines patent processing, saving time and effort for legal professionals while facilitating client acquisition through curated databases. It also minimizes the risk of errors and rejections in patent applications, ensuring smoother processes.'
                itemList={[{
                    subtitle : 'Streamlines patent processing for legal professionals, reducing time and effort.',
                    title    : 'Streamlining Patent Processing',
                }, {
                    subtitle : 'Legal experts benefit from easier access to potential clients and cases through curated patent databases.',
                    title    : 'Enhanced Client Acquisition',
                }, {
                    subtitle : 'AttorneyAI helps mitigate the risk of errors and rejections in patent applications, ensuring smoother processes',
                    title    : 'Risk Mitigation',
                }]} />
            <br />
            <HorizontalSpacer size='sm' />
            <br />

            <FeatureSection
                side='left'
                mediaComponent={
                    <Image
                        hidden={isTablet}
                        alt='Student Patent'
                        width={300}
                        height={300}
                        src={ImageStudenPatent.src} />
                }
                subtitle={'AttorneyAI elevates universities\' patent endeavors by increasing output, fostering collaborative environments, and streamlining processes. It facilitates industry partnerships, technology transfer opportunities, and efficient patent creation and management for researchers, ultimately driving innovation and success.'}
                footer={'AttorneyAI enhances universities\' patent efforts by boosting output, fostering collaboration, and streamlining processes. It facilitates industry partnerships, technology transfer, and efficient patent management, driving innovation and success.'}
                title='BENEFITS TO UNIVERSITIES'
                itemList={[{
                    title    : 'Increased Patent Output',
                    subtitle : 'AttorneyAI boosts the number of patents produced by the university.',
                }, {
                    title    : 'Industry Partnerships',
                    subtitle : 'Facilitates industry partnerships and technology transfer opportunities.',
                }, {
                    title    : 'Collaborative Environment',
                    subtitle : 'Fosters a collaborative environment between students, faculty, and legal professionals.',
                }, {
                    title    : 'Streamlined Processes',
                    subtitle : 'Improves efficiency in patent creation and management for university researchers.',
                }]} />
            <br />
            <HorizontalSpacer size='sm' />
            <br />

            <Text className={classes.supTitle}>
                {'AttorneyAI'}
            </Text>
            <Title
                className={classes.title}
                order={2}>
                {'Your Guide Through the Ins and Outs of Patent Management'}
            </Title>
            <Container
                size={660}
                p={0}>
                <Text
                    c='dimmed'
                    className={classes.description}>
                    {'Leverage the power of AttorneyAI to manage more cases with fewer resources: Benefit from features like bulk discovery analysis, conflict of interest checks, and automated email analysis, ensuring higher client satisfaction and reduced stress.'}
                </Text>
            </Container>
        </Box>
    );
};

/* eslint-enable max-len */
