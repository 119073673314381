import { RequestAccessButton } from '@app/ui/control/RequestAccessButton';
import { HeadlineTitle } from '@app/ui/marketing/HeadlineTitle';
import { FeatureTitle } from '@app/ui/marketing/FeatureTitle';
import { Box, Container, Grid, Title } from '@mantine/core';
import { FeatureBody } from '@app/ui/marketing/FeatureBody';
import { BannerSEO } from '@app/ui/marketing/BannerSEO';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const HomepageAttorneyAI : RFC<Props> = ({ siteData }) =>
    <Box pb='xl'>
        <Grid>
            <Grid.Col span={1} />
            <Grid.Col span={10}>
                <FeatureTitle siteData={siteData} />
            </Grid.Col>
            <Grid.Col span={1} />
            <Grid.Col
                span={12}
                ta='center'>
                <RequestAccessButton />
                <br />
                <br />
                <br />
            </Grid.Col>
            <Grid.Col span={2} />
            <Grid.Col span={8}>
                <FeatureBody />
            </Grid.Col>
            <Grid.Col span={2} />

            <Grid.Col span={2} />
            <Grid.Col span={8}>
                <BannerSEO />
                <br />
                <br />
                <br />
            </Grid.Col>
            <Grid.Col span={2} />
        </Grid>
        <Container pt='xl'>
            <Title
                ta='center'
                fw={200}
                pb='xl'
                mb='xl'>
                {'More Efficient, Less Bureaucracy, More Profitable'}
            </Title>
            <HeadlineTitle />
        </Container>
        <Container
            pb='xl'
            mb='xl'
            ta='center'
            mt='xl'>
            <br />
            <RequestAccessButton />
        </Container>
    </Box>;
