import { SingleServeData } from '../type';


export const RentersAdvantage : SingleServeData = {

    HeadlineSubtitle : 'Renter\'s Advantage',
    HeadlineTitle    : 'Renter\'s Advantage',
    BlurbTitle       : 'Renting Made Easy!',
    BlurbBody        : 'Exciting Blurb',
    Feature          : [{
        Title : 'Renter\'s Advantage FEATURE TITLE',
        Body  : 'OOooo body',
        Icon  : 'src/asset/image/data-policy-0.svg',
    }],
} as const;
