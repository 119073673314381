import { PathwayStatusType } from '@app/constant/enum/model/PathwayStatusTypeEnum';
import { BrowsePageContainer } from '@app/ui/layout/BrowsePageContainer';
import { BrowseUploadHeader } from '@app/ui/browse/BrowseUploadHeader';
import { selectPathwayList } from '@app/client/selector/ListSelector';
import { PathwayModel } from '@app/type/model/PathwayModel';
import { RouteHref } from '@app/constant/route/RouteHref';
import { PathwayList } from '@app/ui/list/PathwayList';
import { NewPatentPanel } from './NewPatentPanel';
import { TaskList } from '@app/ui/list/TaskList';
import { useSelector } from '@app/client/hook';
import { Button, Grid } from '@mantine/core';
import { FcTemplate } from 'react-icons/fc';
import React, { useContext } from 'react';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';
import Link from 'next/link';


export const DashboardContent : RFC = () => {

    const pathwayList : PathwayModel[] = useSelector(selectPathwayList);
    const _taskList  = []; //useSelector(Selector.taskList);

    const { isPhone } = useContext(App);

    // Count all where the status is enrolled
    // pathwayList[1].status === PathwayStatusType.Enrolled
    const showNoContentHeader = pathwayList.filter(pathway =>
        pathway.status === PathwayStatusType.Enrolled,
    ).length === 0;

    return (
        <BrowsePageContainer>
            <BrowseUploadHeader
                rightSectionComponent={
                    <Button
                        aria-label='Start Your Patent'
                        component={Link}
                        size='md'
                        variant='outline'
                        href={RouteHref.BrowsePatentType}>
                        {'Start Your Patent'}
                    </Button>
                }
                icon={<FcTemplate size={80} />}
                title='Dashboard'/>
            {'Helloooo'}
            <Grid
                mt={isPhone ? 'lg' : 'xs'}>
                {showNoContentHeader &&
                    <>
                        <Grid.Col
                            span={{ md : 3}}
                            visibleFrom='md' />
                        <Grid.Col span={{ base : 12, md : 6}}>
                            <NewPatentPanel />
                        </Grid.Col>
                        <Grid.Col
                            span={{ md : 3}}
                            visibleFrom='md' />
                    </>
                }
                <Grid.Col span={{ base : 12, sm : 6 }}>
                    <PathwayList
                        showSortFilter={pathwayList.length > 0}
                        pathwayList={pathwayList}
                        showRowContent={false}
                        title='My Patents' />
                </Grid.Col>
                <Grid.Col span={{ base : 12, sm : 6 }}>
                    <TaskList
                        pathwayMilestoneList={[]}
                        taskList={[]} // taskListaskListt.slice(0, 5)}
                        showSortFilter={_taskList.length > 0}
                        showRowContent={false}
                        title='My Tasks'
                        pathway={null} />
                </Grid.Col>
            </Grid>
        </BrowsePageContainer>
    );
};
