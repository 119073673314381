import { SingleServeData } from '../type';


export const TaxLaw : SingleServeData = {

    HeadlineSubtitle : 'AI Enhanced Tax Assistant',
    HeadlineTitle    : 'TaxAI',
    BlurbTitle       : 'Blurb title AttorneyAI',
    BlurbBody        : 'Blurb body AttorneyAI',
    Feature          : [{
        Title : 'AttorneyAI FEATURE TITLE',
        Body  : 'AttorneyAI Feature Body',
        Icon  : 'src/asset/image/data-policy-0.svg',
    }],
} as const;
