import { Box, Group, Text, rem} from '@mantine/core';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const TitleHeader : RFC<Props> = ({
    title, icon,
}) => {

    const { isPhone } = useContext(App);

    return (
        <Group>
            <Box
                visibleFrom='lg'
                pt='xs'>
                {icon}
            </Box>
            <Text
                fz={rem(isPhone ? 40 : 80)}
                variant='gradient'
                fw={200}
                gradient={{
                    from : 'blue',
                    to   : 'cyan',
                    deg  : 90,
                }}>
                {title}
            </Text>
        </Group>
    );
}
