import { Container } from '@mantine/core';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const BrowsePageContainer : RFC<Props> = ({
    children,
}) => {

    const { isPhone } = useContext(App);

    if(isPhone)
        return (
            <Container fluid={true}>
                {children}
            </Container>
        );

    return (
        <Container
            fluid={true}
            pl='xl'
            ml='xl'
            mr='xl'
            pr='xl'>
            {children}
        </Container>
    );
};
