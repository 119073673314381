import { Text, Grid, rem, Box } from '@mantine/core';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const FeatureTitle : RFC<Props> = ({ siteData }) => {

    const { isPhone } = useContext(App);

    return (
        <Box>
            <Grid gutter={0}>
                <Grid.Col>
                    <Text
                        ta='center'
                        style={{
                            fontSize : isPhone
                                ? rem(70)
                                : rem(130),
                        }}
                        fw={200}
                        variant='gradient'
                        gradient={{
                            from : 'blue',
                            to   : 'green',
                            deg  : 87,
                        }}>
                        {siteData.HeadlineTitle}
                    </Text>
                    <Text
                        ta='center'
                        size='xl'
                        mb='xl'
                        c='dimmed'>
                        <Text
                            fz={32}
                            fw='bold'>
                            {'AI ENHANCED'}
                        </Text>
                        <Text
                            fw={200}
                            fz={42}>
                            {'PATENT & CASE MANAGEMENT'}
                        </Text>
                    </Text>
                </Grid.Col>
            </Grid>
        </Box>
    );
};
