import { SingleServeData } from '../type';


export const HOAnalyser : SingleServeData = {

    HeadlineSubtitle : 'HOAnalyzer Subtitle',
    HeadlineTitle    : 'HOAnalyzer',
    BlurbTitle       : 'HOA Agreements, Simplified.',
    BlurbBody        : 'Exciting Blurb',
    Feature          : [{
        Title : 'HOAnalyzer FEATURE TITLE',
        Body  : 'OOooo body',
        Icon  : 'src/asset/image/data-policy-0.svg',
    }],
} as const;
