import { Card, Text, Group, rem, Stack } from '@mantine/core';
import { SEOPageLookup } from '@app/ui/SEOPageData';
import classes from './BannerSEO.module.css';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import Image from 'next/image';
import Link from 'next/link';


export const TileSEO : RFC<Props> = ({ url }) => {

    const pageData = SEOPageLookup[url];

    return (
        <Link
            className={classes.tile}
            href={url}
            style={{
                textDecoration : 'none',
            }}>
            <Card
                withBorder={false}
                radius='md'
                shadow='xl'
                className={classes.card}
                p='xl'
                style={{
                    borderRadius : rem(30),
                }}>
                <Text
                    className={classes.title}
                    fz={rem(32)}
                    fw={600}
                    mt='xs'
                    mb='md'>
                    {pageData.title}
                </Text>
                <Text
                    ml='lg'
                    c='dimmed'
                    fw={500}
                    size={rem(20)}>
                    {pageData.subtitle}
                </Text>
                <Group
                    wrap='nowrap'
                    gap={0}>
                    <Stack
                        align='flex-start'
                        justify='flex-start'
                        p='lg'>
                        <Text
                            ml='lg'
                            fz={rem(16)}>
                            {pageData.footer}
                        </Text>
                    </Stack>
                    {pageData.imageData &&
                        <Image
                            style={{
                                padding      : '8px',
                                borderRadius : '10%',
                            }}
                            src={pageData.imageData.src}
                            width={180}
                            height={180}
                            alt='some text' />
                    }
                </Group>
            </Card>
        </Link>
    );
};
