import { HiOutlineClipboardDocumentCheck } from 'react-icons/hi2';
import { Text, Button, Center, Paper, rem } from '@mantine/core';
import classes from './NewPatentPanel.module.css';
import { RFC } from '@app/ui/type';
import Link from 'next/link';
import React from 'react';
import { RouteHref } from '@app/constant/route/RouteHref';


export const NewPatentPanel : RFC = () =>
    <Paper
        p='xl'
        m='xl'>
        <Center>
            <Button
                aria-label='Start Your Patent'
                style={{ boxShadow : '0px 18px 55px 11px rgba(0,0,128,0.3)' }}
                rightSection={<HiOutlineClipboardDocumentCheck size={40} />}
                gradient={{ from : 'blue', to : 'violet', deg : 345 }}
                href={RouteHref.BrowsePatentType}
                className={classes.button}
                variant='gradient'
                component={Link}
                radius='lg'
                size='xl'>
                <Text
                    fz={rem(32)}
                    fw={500}>
                    {'Start Your Patent'}
                </Text>
            </Button>
        </Center>
    </Paper>
