import { SingleServeData } from '../type';


export const TicketBlaster : SingleServeData = {

    HeadlineSubtitle : 'TicketBlaster Subtitle',
    HeadlineTitle    : 'TicketBlaster',
    BlurbTitle       : 'Drag. Drop and DESTROY your tickets.',
    BlurbBody        : 'Exciting Blurb',
    Feature          : [{
        Title : 'TicketBlaster fet title',
        Body  : 'OOooo body',
        Icon  : 'src/asset/image/data-policy-0.svg',
    }],
} as const;

