import { SingleServeData } from '../type';


export const PatentLaw : SingleServeData = {

    HeadlineSubtitle : 'AI Powered IP Law',
    HeadlineTitle    : 'IP AttorneyAI',
    BlurbTitle       : 'Blurb title Patent',
    BlurbBody        : 'Blurb body Patent',
    Feature          : [{
        Title : 'Patent FEATURE TITLE',
        Body  : 'Patent Feature Body',
        Icon  : 'src/asset/image/data-policy-0.svg',
    }],
} as const;
