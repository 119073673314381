import { TemplateVisibilityType } from '@app/constant/enum/ui/TemplateVisibilityTypeEnum';
import { SingleServeSiteType } from '@app/constant/enum/ui/SingleServeSiteTypeEnum';
import { HomepageAttorneyAI } from '@app/ui/content/HomepageAttorneyAI';
import { SiteData } from '@app/ui/content/SingleServeContent/constant';
import { HomepagePatentLaw } from '@app/ui/content/HomepagePatentLaw';
import { DashboardContent } from '@app/ui/content/DashboardContent';
import { TemplateApp } from '@app/ui/template/TemplateApp';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import { useDispatch } from '@app/client/hook';
import { ReactNode, useEffect } from 'react';
import { Action } from '@app/client/action';
import { RFC } from '@app/ui/type';
import { Env } from '@app/Env';


const NEXT_PUBLIC_APP_ID = Env.getValue<string>('NEXT_PUBLIC_APP_ID');


export const PageHomepage : RFC = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(Action.ensureCorrectSubdomain());
    });

    let content : ReactNode | null = null;

    switch(NEXT_PUBLIC_APP_ID) {
        case SingleServeSiteType.AttorneyAI: content = <HomepageAttorneyAI siteData={SiteData.AttorneyAI} />; break;
        case SingleServeSiteType.PatentLaw : content = <HomepagePatentLaw  siteData={SiteData.PatentLaw } />;  break;

        default: content = <HomepageAttorneyAI siteData={SiteData.AttorneyAI} />; break;
    }

    return (
        <>
            <SignedIn>
                <TemplateApp templateVisibility={TemplateVisibilityType.Private}>
                    <DashboardContent />
                </TemplateApp>
            </SignedIn>
            <SignedOut>
                <TemplateApp templateVisibility={TemplateVisibilityType.Public}>
                    {content}
                </TemplateApp>
            </SignedOut>
        </>
    );
};
