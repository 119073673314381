import { SingleServeSiteType } from '@app/constant/enum/ui/SingleServeSiteTypeEnum';
import { RentersAdvantage } from './RentersAdvantage';
import { ContractGenie } from './ContractGenie';
import { TicketBlaster } from './TicketBlaster';
import { SingleServeData } from '../type';
import { HOAnalyser } from './HOAnalyser';
import { AttorneyAI } from './AttorneyAI';
import { BobLawBot } from './BobLawBot';
import { PatentLaw } from './PatentLaw';
import { TaxLaw } from './TaxLaw';


export const SiteData : Record<SingleServeSiteType, SingleServeData> = {
    [SingleServeSiteType.RentersAdvantage] : RentersAdvantage,
    [SingleServeSiteType.ContractGenie]    : ContractGenie,
    [SingleServeSiteType.TicketBlaster]    : TicketBlaster,
    [SingleServeSiteType.HOAnalyser]       : HOAnalyser,
    [SingleServeSiteType.AttorneyAI]       : AttorneyAI,
    [SingleServeSiteType.BobLawBot]        : BobLawBot,
    [SingleServeSiteType.PatentLaw]        : PatentLaw,
    [SingleServeSiteType.TaxLaw]           : TaxLaw,
} as const;
