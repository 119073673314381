import { OrderField, SortField, SortFilterConfig } from './type';
import { PathwayModel } from '@app/type/model/PathwayModel';


export const sortFilterPathways = (
    pathwayList : PathwayModel[],
    config      : SortFilterConfig,
) : PathwayModel[] => {

    const filteredPathwayList = pathwayList.filter(
        pathway => pathway.status === config.filter,
    );

    const sortAndOrdedPathwayList = filteredPathwayList.sort((a, b) => {

        if(config.sortBy === SortField.CreatedAt)  {

            const thisOne = new Date(a.createdAt!).getTime();
            const thatOne = new Date(b.createdAt!).getTime();

            return config.orderBy === OrderField.Ascending
                ? thisOne - thatOne
                : thatOne - thisOne;
        }

        const youCouldGoWithThis = new Date(a.createdAt!).getTime();
        const youCouldGoWithThat = new Date(b.createdAt!).getTime();

        return config.orderBy === OrderField.Descending
            ? youCouldGoWithThis - youCouldGoWithThat
            : youCouldGoWithThat - youCouldGoWithThis;
    });

    return sortAndOrdedPathwayList;
}
