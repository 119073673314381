import { RouteHref } from '@app/constant/route/RouteHref';
import { TileSEO } from '@app/ui/marketing/TileSEO';
import { Paper, SimpleGrid } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const BannerSEO : RFC = () =>
    <Paper >
        <SimpleGrid
            cols={{
                base : 1,
                md   : 1,
                lg   : 2,
            }}>
            <TileSEO url={RouteHref.SEOHighlyLegalAndTechnicalAspectsOfPatents} />
            <TileSEO url={RouteHref.SEOCustomizedLessonsForBeginnersAndExperts} />
            <TileSEO url={RouteHref.SEOClearPriorArtInPatentApplications} />
            <TileSEO url={RouteHref.SEOSpecificationInPatentApplications} />
            <TileSEO url={RouteHref.SEOIndustrialApplicabilityOfPatents} />
            <TileSEO url={RouteHref.SEOKanbanBoardForProjectManagement} />
            <TileSEO url={RouteHref.SEOProvisionalPatentApplication} />
            <TileSEO url={RouteHref.SEODocumentUploadAndAnalysis} />
            <TileSEO url={RouteHref.SEOScoringAndRiskAssessment} />
            <TileSEO url={RouteHref.SEONonObviousnessInPatents} />
            <TileSEO url={RouteHref.SEOClaimsReferenceChecking} />
            <TileSEO url={RouteHref.SEOPatentableSubjectMatter} />
            <TileSEO url={RouteHref.SEOBiotechnologicalPatent} />
            <TileSEO url={RouteHref.SEONoveltyInPatents} />
            <TileSEO url={RouteHref.SEOUtilityInPatents} />
            <TileSEO url={RouteHref.SEOSoftwarePatent} />
            <TileSEO url={RouteHref.SEOClaimsBuilder} />
            <TileSEO url={RouteHref.SEODesignPatent} />
            <TileSEO url={RouteHref.SEOPlantPatent} />
            <TileSEO url={RouteHref.SEONoteTaking} />
        </SimpleGrid>
    </Paper>;
