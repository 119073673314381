import { IconArrowDownRight, IconArrowUpRight } from '@tabler/icons-react';


export const icons = {
    up   : IconArrowUpRight,
    down : IconArrowDownRight,
};

export const data = [{
    id       : 'key1',
    label    : 'Client Satisfaction',
    stats    : '100%',
    progress : 100,
    color    : 'green',
    icon     : 'up',
}, {
    id       : 'key2',
    label    : 'Stress & Effort',
    stats    : 'Down',
    progress : 72,
    color    : 'blue',
    icon     : 'down',
}, {
    id       : 'key3',
    label    : 'Clients Onboarded',
    stats    : '+23',
    progress : 65,
    color    : 'teal',
    icon     : 'up',
}] as const;
