import { RingProgress, Text, Center, rem, Grid } from '@mantine/core';
import { data, icons } from './constant';
import { RFC } from '@app/ui/type';


export const HeadlineTitle : RFC = () => {
    const stats = data.map(stat => {
        const Icon = icons[stat.icon];

        return (
            <Grid.Col
                span={{ base : 12, xs : 6, sm : 4 }}
                key={stat.id}>
                <Center>
                    <RingProgress
                        size={80}
                        roundCaps={true}
                        thickness={8}
                        sections={[{
                            value : stat.progress, color : stat.color }]}
                        label={
                            <Center>
                                <Icon
                                    style={{
                                        width  : rem(20),
                                        height : rem(20),
                                    }}
                                    stroke={1.5} />
                            </Center>
                        }/>
                    <div>
                        <Text
                            c='dimmed'
                            size='xs'
                            tt='uppercase'
                            fw={700}>
                            {stat.label}
                        </Text>
                        <Text
                            fw={700}
                            size='xl'>
                            {stat.stats}
                        </Text>
                    </div>
                </Center>
            </Grid.Col>
        );
    });

    return (
        <Grid>
            {stats}
        </Grid>
    )
}
