
export enum SingleServeSiteType {
    RentersAdvantage = 'RentersAdvantage',
    ContractGenie    = 'ContractGenie',
    TicketBlaster    = 'TicketBlaster',
    HOAnalyser       = 'HOAnalyser',
    AttorneyAI       = 'AttorneyAI',
    PatentLaw        = 'PatentLaw',
    BobLawBot        = 'BobLawBot',
    TaxLaw           = 'TaxLaw',
}
