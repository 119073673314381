import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { FileUploader } from '@app/ui/control/FileUploader';
import { Breadcrumb } from '@app/ui/static/Breadcrumb';
import { NoContent } from '@app/ui/control/NoContent';
import { useDispatch } from '@app/client/hook';
import { TitleHeader } from './TitleHeader';
import { Box, Group } from '@mantine/core';
import { ModelStash } from '@app/type/model/ModelStash';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import { Action } from '@app/client/action';


export const BrowseUploadHeader : RFC<Props> = ({
    fileUploaderValue, title,
    currentBreadCrumbs, icon,
    rightSectionComponent,
}) => {
    const dispatch = useDispatch();

    const onUploadComplete = (
        model      : CoreModel,
        modelStash : ModelStash,
    ) : void => {
        dispatch(Action.updateStashNoSync(modelStash));

        switch(model.modelType) {
            case ModelType.Pathway: dispatch(Action.selectPathwayAsset(null));           break;
            case ModelType.Asset  : dispatch(Action.pushRoute(`/document/${model.id}`)); break;
        }
    };

    const handleUploadChange = (_model : CoreModel) : void => {};

    return (
        <Group justify='space-between'>
            <Box>
                <TitleHeader
                    fileUploaderValue={fileUploaderValue}
                    title={title}
                    icon={icon} />
                {currentBreadCrumbs &&
                    <Breadcrumb value={currentBreadCrumbs} />
                }
            </Box>
            {rightSectionComponent &&
                <Box>
                    {rightSectionComponent}
                </Box>
            }
            {fileUploaderValue &&
                <Box visibleFrom='sm'>
                    <FileUploader
                        onUploadComplete={onUploadComplete}
                        onChange={handleUploadChange}
                        value={fileUploaderValue}>
                        <NoContent title='Upload Document - Drag and Drop or Click' />
                    </FileUploader>
                </Box>
            }
        </Group>
    );
}
