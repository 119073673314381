import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { selectPagePathway } from '@app/client/selector/PageSelector';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { updateComposeModelAction } from '@app/client/CommonAction';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { UserType } from '@app/constant/enum/model/UserTypeEnum';
import { useDispatch, useSelector } from '@app/client/hook';
import { SortPathwayHeader } from './SortPathwayHeader';
import { RouteLink } from '@app/ui/control/RouteLink';
import { DefaultSortFilterConfig } from './constant';
import { Props, SortFilterConfig } from './type';
import { Pathway } from '@app/ui/model/Pathway';
import { Spacer } from '@app/ui/layout/Spacer';
import { sortFilterPathways } from './Logic';
import { Text, rem } from '@mantine/core';
import React, { useState } from 'react';
import { RFC } from '@app/ui/type';
import { CA } from '@app/type/framework/core/CoreAction';
import { PathwayModel } from '@app/type/model/PathwayModel';
import { Action } from '@app/client/action';


export const PathwayList : RFC<Props> = ({
    showSortFilter = true, pathwayList, title,
    relationshipConfig, showRowContent,
}) => {

    const [sortFilterConfig, setSortFilterConfig] =
        useState<SortFilterConfig>(DefaultSortFilterConfig);

    const { dialogModalStateType, model } = useSelector(selectPagePathway);

    const dispatch = useDispatch();

    const handleChangeCompose  = (newPathway : PathwayModel) : CA =>
        dispatch(updateComposeModelAction(newPathway));

    const handleNewModal = () : CA =>
        dispatch(Action.openNewModelDialog(ModelType.Pathway));

    const handleSortFilterConfigChange = (config : SortFilterConfig) : void =>
        setSortFilterConfig(config);

    const sortedFilterdPathways = showSortFilter
        ? sortFilterPathways(pathwayList, sortFilterConfig)
        : pathwayList;

    return (
        <BrowseModelLayout
            dialogModalStateType={dialogModalStateType}
            relationshipConfig={relationshipConfig}
            modelList={sortedFilterdPathways}
            newButtonContent='Start a Patent'
            modelType={ModelType.Pathway}
            onNewModal={handleNewModal}
            composingModel={model}
            noItemsContent={
                <Text
                    fz={rem(32)}
                    fs='italic'
                    c='dimmed'
                    fw={200}>
                    {'- No Patents -'}
                </Text>
            }
            headerComponent={
                showSortFilter &&
                    <SortPathwayHeader
                        onChange={handleSortFilterConfigChange}
                        config={sortFilterConfig} />
            }
            title={title ?? 'Patents'}
            renderItem={item =>
                <RouteLink model={item}>
                    <Pathway
                        showRowContent={showRowContent}
                        value={item as PathwayModel}
                        selectedMilestoneHash={null}
                        userType={UserType.Generic}
                        mode={ControlModeType.Row}
                        showManageControls={true}
                        isResearching={false} />
                    <Spacer showLine={false} />
                </RouteLink>
            }
            renderCompose={
                <Pathway
                    showRowContent={showRowContent}
                    onChange={handleChangeCompose}
                    mode={ControlModeType.Create}
                    selectedMilestoneHash={null}
                    userType={UserType.Generic}
                    showManageControls={false}
                    isResearching={false}
                    value={model} />
            } />
    );
};
